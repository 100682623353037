<template>
    <div>
        <v-card>
            <v-card-title class="title">
                <v-spacer></v-spacer>
                <print-buttons :downloadUrl="downloadUrl" :passAuthToReport='true' :pdf="true" :excel="false"
                               v-if="form.items.data.length > 0"></print-buttons>
            </v-card-title>

            <v-card-title class="title">
                <v-flex xs4>
                    <v-autocomplete
                        outlined dense
                        class="pa-0 pl-2"
                        v-model="teacher"
                        :items="items"
                        :loading="isLoading"
                        :search-input.sync="search"
                        hide-no-data
                        persistent-hint
                        item-text="name"
                        item-value="id"
                        :error-messages="form.errors.get('teacher_id')"
                        label="Teacher"></v-autocomplete>
                </v-flex>
                <v-flex xs2>
                    <v-btn color="primary" class="mt-n8" outlined @click="get()" :disabled="!teacher">Search</v-btn>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- <v-container> -->
            <template>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-card>
                            <v-card-title>
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                                    :headers="headers"
                                    :items="form.items.data"
                                    :options.sync="pagination"
                                    :server-items-length="form.items.meta.total"
                                    :loading="form.loading">
                                <template  v-slot:item="{index, item}">
                                    <tr>
                                        <td>{{ index + form.items.meta.from }}</td>
                                        <td class="text-xs-left">{{ item.subject_name }}</td>
                                        <td class="text-xs-left">{{ item.title }}</td>
                                        <td class="text-xs-left">{{ item.grade }}<sup>"{{item.section}}"</sup></td>
                                        <td class="text-xs-left">{{ item.assignment_date }}</td>
                                        <td class="text-xs-left">{{ item.submission_date }}</td>
                                        <td class="text-xs-left">{{ item.teacher }}</td>
                                        <td class="text-xs-left">{{ item.primary_mobile }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-flex>
                </v-layout>
            </template>
        <!-- </v-container> -->
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import Form from '@/library/Form'
    import {getPropertyFromArrayObject, nepNumberToEng, engNumberToNep} from '@/library/helpers'
    import {validateDate} from "../../../../../library/helpers";


    const NepaliDate = require('nepali-date');
    export default {
        data: () => ({
            form: new Form({}, '/api/report/academic/assignment/not-check-teacher'),
            teachers: [],
            teacher: '',
            search: '',
            isLoading:false,
            downloadUrl:'',
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false, width: 100},
                {text: 'Title', align: 'left', value: 'title', sortable: false,},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false,},
                {text: 'Assignment Date', align: 'left', value: 'assignment_date', sortable: false},
                {text: 'Submission Date', align: 'left', value: 'submissiont_date', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false},
                {text: 'Mobile', align: 'left', value: 'primary_mobile', sortable: false},
            ],

            pagination: {
                rowsPerPage: 10,
            },
        }),

        computed: {
            ...mapState(['batch']),
            items() {
                return this.teachers.map(entry => {
                    return Object.assign({}, entry, {name: entry.name})
                })
            },
        },
        mounted() {
            this.get();
        },
        watch: {
            'grade': function () {
                this.section = '';
                this.form.items.data = [];
            },
            'section': function (v) {
                this.form.items.data = [];
            },
            'assignment_date': function () {
                this.form.items.data = [];
            },
            search(val) {
                if (!val) return;
                if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=teacher&search=' + val)
                    .then(({data}) => {
                        this.teachers = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
        },
        methods: {
            nepNumberToEng,
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&teacherId=' + this.teacher;
            },


            get(params) {
                if(this.teacher){
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        this.downloadUrl = data.download_url;
                    });
                }

            },
        }
    }
</script>
<style lang="scss">
    .completed {
        border-radius: 11px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        margin: 5px;
    }

    .bred {
        background: #ff5252;
    }

    .bgreen {
        background: #4caf50;
    }

    .byellow {
        background: #ff9800;
    }
</style>